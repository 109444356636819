.orientatori-container {
    position: absolute;
    left: 50%;
    transform: translateX(-43%);
    width: 86%;
    font-family: 'Poppins', sans-serif;
}
.termini-condizioni {
    width: 60%;
    margin: 0 auto;
    margin-top: 30px;
}
.indietro{
    position: fixed;
    cursor: pointer;
    background-color: #3471CC;
    top: 2rem;
    left: 3rem;
    font-size: 35px;
    width: 80px;
    padding: 6px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    border-radius: 10px;
}
.termini-condizioni > p > span {
    font-weight: 500px;
}
.termini-condizioni > p {margin-top: 20px; margin-bottom: 50px;}
.orientatori-container-closed {
    position: absolute;
    left: 50%;
    transform: translateX(-48%);
    width: 96%;
    font-family: 'Poppins', sans-serif;
}

.orientatori-container-closed,
.orientatori-container {
    .topDash {
        margin-top: 30px;
    }
}

.orientatori {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 3rem;
    width: 100%;
    padding-bottom: 40px;

    #lastroworient {
        display: flex;
        justify-content: space-between;
        align-items: center;

        svg {
            cursor: pointer;
            fill: #bb3133;
            height: 22px;

            &:hover {
                fill: red;
            }
        }
    }
}

.Table {
    width: 60% !important;

}

#table-filters-orient div {
    margin: 0 !important;
    margin-bottom: 30px !important;
}


.right-orientatori {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    width: 30%;
    justify-content: center;
    align-items: center;
}

.classifica {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-height: 175px;
    overflow-y: auto;
}

.classifica>div {
    width: 90%;
}


.classifica-nomi {
    width: 100%;
    border-bottom: 1px solid #181818;
    color: #181818;
    overflow-y: auto;
    padding: 0px 4px;
    font-size: 14px;

    display: grid;
    grid-template-columns: repeat(10, 1fr);
}


.classifica-nomi span {
    font-size: 20px;
    font-weight: 500;
    color: gray;
}

.classifica-nomi #s1 {
    grid-column: span 1;
}

.classifica-nomi #s2 {
    grid-column: span 6;
}

.classifica-nomi #s3 {
    grid-column: span 3;
}

.classifica-nomi:first-of-type {
    margin-top: 10px;
}


#overflowhandler {
    overflow-y: auto;
}

#overflowhandler::-webkit-scrollbar, .orientatore-table::-webkit-scrollbar {
    width: 8px;
}

#overflowhandler::-webkit-scrollbar-track, .orientatore-table::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

#overflowhandler::-webkit-scrollbar-thumb, .orientatore-table::-webkit-scrollbar-thumb {
    background-color: #3471CC;
    border-radius: 4px;
}

.add-orientatori {
    width: 90%;
    border-radius: 20px;
    background: white;
    box-shadow: 0px 0px 20px 2px #80808029;
    padding: 25px 35px;
    margin-top: 30px;
}
.passalead-rec{
    margin-top: 1rem;
    padding: 0 20px;
}
.add-orientatori h4 {
    width: 90%;
    margin-left: 5%;
}
.passalead-form{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 50px;
}
.passalead-form .wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}
.passalead-form .wrapper > div {
    display: flex;
    align-items: center;
}
.passalead-form button{
    background-color: #3471CC;
    margin: 0 auto;
    padding: 8px 16px;
    font-size: 13px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    margin-top: 15px;
}
.passalead-form input{
    border: none;
    border-bottom: 1px solid #3471CC;
    margin-left: 10px;
}
.input-item {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    justify-content: center;
    align-items: start;
    width: 100%;
}
.orientatore-table{
    overflow-y: auto;
    max-height: 100vh;
}
.input-item input {
    border: none;
    background-color: #d9d9d95b;
    border-radius: 5px;
    width: 100%;
    font-size: 12px !important;
    padding: 8px;
}

.input-item label {
    font-size: 16px !important;
}

.input-item label::after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    background: url(../imgs/penna.svg);
    background-size: cover;
}
.input-orientatori {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
    width: 90%;
    margin-left: 5%;
}

.btn-orie {
    background-color: #3471CC;
    margin: 0 auto;
    padding: 8px 16px;
    font-size: 13px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    margin-top: 15px;
    /* width: 30%; */
}

.btn-orie-add {
    background-color: #3471CC;
    margin: 0 auto;
    padding: 8px 16px;
    font-size: 13px;
    border: none;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    margin-top: 15px;
}

.popup-orientatore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 50%;
    z-index: 10000;
    margin-top: 10vh;
    margin-left: 10vw;
    position: fixed;
    background-color: white;
    border-radius: 20px;
    padding: 50px 20px;
    box-shadow: 0px 0px 20px 2000px rgba(0, 0, 0, 0.77)
}



#table-container {
    border-radius: 20px;
    padding: 30px 20px;
    height: 100%;
    margin-top: 0;
    max-height: 50vh;
    box-shadow: none;
}


#Table {
    padding: 10px;
    background-color: white !important;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 2px #80808029;

}

#orientatorisearch svg {
    height: 50px;
}

#orientatorisearch input {
    border: none;
    outline: none;
    background-color: white;
    border-bottom: 1px solid;
    font-size: 20px;
}


.filtraorient {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 50px;
}

.filtraorient label {
    color: black;
    font-weight: 600;
    text-decoration: underline;
    font-size: 20px;
}

.filtraorient .wrapper {
    display: flex;
    gap: 3rem;
}


.filtraorient input {
    background-color: var(--bg);
    border: none;
    outline: none;
    color: #3471CC;
    font-size: 20px;
    text-align: end;
    width: fit-content;
    position: relative;
}

.filtraorient input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.orientatori {
    margin-top: 90px;
}


// @media screen and (max-width: 500px) {
//     .orientatori-container {
//         left: 0%;
//         transform: translateX(0%);
//         width: 100% !important;
//         margin-top: 20px;
//     }

//     .orientatori {
//         flex-wrap: wrap;
//         gap: 10rem;
//         width: 100%;
//     }

//     .Table {
//         width: 90% !important;
//         margin: 0 auto;
//     }

//     .right-orientatori {
//         width: 84%;
//         margin-top: -90px;
//     }

//     .classifica {
//         display: flex;
//         flex-direction: column;
//         gap: 1rem;
//         justify-content: center;
//         align-items: center;
//         width: 100%;
//         max-height: 175px;
//         overflow-y: auto;
//     }

//     .add-orientatori {
//         border-radius: 20px;
//         background: white;
//         box-shadow: 0px 0px 20px 2px #80808029;
//         padding: 25px 35px;
//         margin-top: 30px;
//         width: 100%;
//     }
// }

@media screen and (width <=1600px) {
    .orientatori {
        .add-orientatori {
            * {
                font-size: 18px !important;
            }

            button {
                font-size: 15px !important;
            }
        }
    }

}



@media screen and (width <=1200px) {
    .orientatori {
        margin-top: 30px !important;

        .Table#Table {
            width: 100% !important;

            * {
                font-size: 15px !important;
            }
        }

        .right-orientatori {
            width: unset !important;
            display: grid !important;
            grid-template-columns: repeat(10, 1fr);

            h5 {
                grid-column: span 1;
            }

            .classifica {
                grid-column: span 4;
                max-height: 300px !important;
            }

            .add-orientatori {
                grid-column: span 5;
                width: 100% !important;
            }
        }

        .classifica-nomi #s2 {
            grid-column: span 5;
        }

        .classifica-nomi #s3 {
            grid-column: span 4;
        }

    }

    .orientatori-container-closed {
        width: 87% !important;
        transform: translateX(-48%) !important;
    }

    .orientatori-container {
        width: 72% !important;
        transform: translateX(-39%) !important;
    }

    .filtraorient {
        * {
            font-size: 17px !important;
        }
    }

    .right-orientatori {
        * {
            font-size: 16px !important;
        }
    }


    .orientatori {
        .add-orientatori {
            * {
                font-size: 16px !important;
            }

            button {
                font-size: 14px !important;
            }
        }
    }

}

@media screen and (width <=900px) {
    .orientatori {
        .right-orientatori {
            margin-top: 40px !important;
            width: 100% !important;

            h5 {
                grid-column: 1 /span 10;
            }

            .classifica {
                grid-column: 1/ span 10;

            }

            .add-orientatori {
                grid-column: 1/ span 10;

            }
        }
    }

    .orientatori-container {
        width: 85% !important;
        transform: translateX(-50%) !important;
    }
}

@media screen and (width <=600px) {

    #oftable {
        overflow-x: auto;
    }

    .orientatori-container,
    .orientatori-container-closed {
        .topDash {
            margin-top: 30px !important;
        }

        .filtraorient {
            margin-top: 30px !important;
            margin-left: 0 !important;
        }
    }

    .orientatori-container,
    .orientatori-container-closed {
        .filtraorient {
            margin-bottom: 10px !important;
            margin-top: 50px !important;
            margin-left: 0 !important;

            .wrapper {
                flex-direction: column !important;
                gap: 1rem !important;
            }
        }

        .orientatori {
            #table-filters-orient {
                padding: 0 !important;
                flex-direction: column !important;

                svg {
                    height: 30px !important;
                }

                input {
                    font-size: 15px !important;
                }
            }

            .table-container {
                * {
                    font-size: 15px !important;
                }
            }
        }
    }
}